"use client";
import { jsxs as D, jsx as p } from "react/jsx-runtime";
import "../MessagePanelV2/MessagePanel.js";
import { MessageInput as F } from "../MessagePanelV2/components/MessageInput/index.js";
import "@deputy-ai/api/api";
import "@deputy-ai/api/api/gen/models/MessageParticipantType";
import "@deputy-ai/api/model/message";
import "@deputy-ai/api/model/visitor";
import { forwardRef as _, useState as S, useMemo as $ } from "react";
import "uuid";
import "../realtime/PusherProvider.js";
import "@deputy-ai/api/api/gen/models/TaskRealm";
import "@deputy-ai/api/api/singleton";
import "@deputy-ai/api/api/sse";
import "@deputy-ai/api/model/response";
import "@deputy-ai/api/api/request";
import "@deputy-ai/api/model/list";
import "ahooks";
import { MessageContainer as L } from "./components/MessageContainer.js";
import { useLoadMessages as O } from "./hooks/useLoadMessages.js";
import { usePullSSEResult as W } from "./hooks/usePullSSEResult.js";
import { useRealtimeMessage as B } from "./hooks/useRealtimeMessage.js";
import { MessagingEventType as M, MessagingMessageType as G, MessagingChannelType as H, MessagingStatus as f } from "./messaging/types.js";
import { toMessageMeta as J } from "./messaging/utils.js";
import { cn as K } from "@deputy-ai/common";
import { uniqueId as T } from "lodash";
const Q = _(
  (w, V) => {
    const {
      conversationId: a,
      installedApp: t,
      user: c,
      listMessagesApi: I,
      sendMessageApi: b,
      uploadImageApi: x,
      showAvatar: s,
      renderMessageContent: C = (e) => e.payload.message,
      messageBubblePosition: r,
      className: k
    } = w, [N, P] = S(""), [R, U] = S(), { messages: q, loadMore: E, appendMessage: o, removeMessage: d } = O({
      request: I,
      deps: {
        installedAppUid: t == null ? void 0 : t.uid,
        orgUid: t == null ? void 0 : t.orgUid,
        conversationUid: a
      },
      combineDeps: (e) => ({
        path: {
          installedAppUid: e == null ? void 0 : e.installedAppUid,
          orgUid: e == null ? void 0 : e.orgUid,
          conversationUid: e == null ? void 0 : e.conversationUid
        },
        query: {
          page: e == null ? void 0 : e.page,
          pageSize: e == null ? void 0 : e.pageSize
        }
      })
    }), v = async (e) => {
      const { orgUid: n, uid: g } = t ?? {};
      if (!n || !g)
        return;
      const u = T(), m = {
        uid: u,
        orgUid: n,
        conversationUid: a,
        installedAppUid: g,
        eventType: M.CustomerMessage,
        payload: {
          message: e,
          messageType: G.Text,
          channel: H.Web
        },
        participant: c,
        status: f.Sending,
        eventTime: (/* @__PURE__ */ new Date()).toISOString()
      };
      return o(m), await b({
        body: {
          orgUid: n,
          installedAppUid: g,
          conversationUid: a,
          message: {
            conversationUid: a,
            installedAppUid: g,
            eventType: "",
            payload: {
              message: e
            },
            participant: c
          }
        },
        path: {
          orgUid: n,
          installedAppUid: g,
          conversationUid: a
        }
      }).then((i) => {
        var l, y, h;
        (l = i == null ? void 0 : i.data) != null && l.message && (d(u), o((y = i == null ? void 0 : i.data) == null ? void 0 : y.message)), i != null && i.error && (d(u), m.status = f.Failed, o(m)), P(((h = i == null ? void 0 : i.data) == null ? void 0 : h.taskUid) ?? "");
      }).catch(() => {
        d(u), m.status = f.Failed, o(m);
      }), await Promise.resolve();
    };
    W({
      appendMessage: (e) => {
        t != null && t.uid && o({
          ...e,
          installedAppUid: t == null ? void 0 : t.uid,
          conversationUid: a,
          uid: T(),
          orgUid: t == null ? void 0 : t.orgUid,
          eventType: M.Ask
        });
      },
      setPendingMessage: (e) => {
        if (!e || !(t != null && t.uid) || !a) {
          U(void 0);
          return;
        }
        U({
          ...e,
          conversationUid: a,
          orgUid: t == null ? void 0 : t.orgUid,
          eventType: M.Ask,
          installedAppUid: t.uid
        });
      },
      taskId: N,
      realm: "human_agent"
    });
    const j = $(() => {
      var e;
      return `conversation.agent-${(e = t == null ? void 0 : t.settings) == null ? void 0 : e.agent_id}.${a}`;
    }, [t]);
    B({
      channelId: j,
      appendMessage: (e) => {
        e.participant.type !== c.type && o(e);
      }
    });
    const z = (e) => {
      e.uid && (d(e.uid), v(e.payload.message));
    };
    return /* @__PURE__ */ D("div", { className: K("h-[600px] w-96 overflow-hidden relative", k), children: [
      /* @__PURE__ */ p(
        L,
        {
          className: "pb-12",
          onRefresh: E,
          messages: q,
          getMessageMeta: (e) => J(e),
          renderMessageContent: ({ origin: e }) => C(e),
          showAvatar: typeof s == "function" ? ({ origin: e }, n) => s == null ? void 0 : s(e, n) : s,
          showName: !1,
          resendMessage: ({ origin: e }) => z(e),
          messageBubblePosition: ({ origin: e }) => typeof r == "function" ? r == null ? void 0 : r(e) : r,
          pendingMessage: R
        }
      ),
      /* @__PURE__ */ p("div", { className: "absolute -bottom-2 w-full", children: /* @__PURE__ */ p(
        F,
        {
          uploadImageApi: x,
          sendMessage: async (e) => await v(e.preview)
        }
      ) })
    ] });
  }
);
Q.displayName = "Chatbox";
export {
  Q as Chatbox
};
