import { MessagingParticipantType as p, MessagingMessageType as r } from "./types.js";
function n(t) {
  var a, i;
  return {
    uid: t.uid,
    participantUid: t.participant.externalId,
    isMe: t.participant.type === p.Visitor,
    avatarSrc: t.participant.avatar,
    name: t.participant.name,
    eventTime: t.eventTime,
    skipScrollToBottom: t.participant.type === p.System,
    image: t.payload.messageType === r.Image ? {
      // eslint-disable-next-line @typescript-eslint/non-nullable-type-assertion-style
      src: (a = t.payload.media) == null ? void 0 : a.mediaUrl,
      alt: (i = t.payload.media) == null ? void 0 : i.altText
    } : void 0
  };
}
export {
  n as toMessageMeta
};
