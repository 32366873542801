import { useState as f, useEffect as w } from "react";
function z({
  deps: e,
  request: v,
  combineDeps: n
}) {
  const [s, g] = f([]), [S, m] = f(1);
  w(() => {
    c();
  }, Object.values(e));
  const c = async () => {
    const a = { ...e, page: S, pageSize: (e == null ? void 0 : e.pageSize) ?? 20 }, o = (n == null ? void 0 : n(a)) ?? a;
    return await v(o).then((t) => {
      var u, i, M, l;
      const d = s.toSpliced(
        0,
        0,
        ...((i = (u = t == null ? void 0 : t.data) == null ? void 0 : u.messages) == null ? void 0 : i.toReversed()) ?? []
      );
      return g(d), (M = t == null ? void 0 : t.data) != null && M.messages && m((p) => p + 1), { noMore: d.length >= ((l = t == null ? void 0 : t.data) == null ? void 0 : l.total) };
    });
  };
  return {
    messages: s,
    loadMore: c,
    appendMessage: (a) => {
      g((o) => o.concat(a));
    },
    removeMessage: (a) => {
      const o = s.findIndex((t) => t.uid === a);
      g((t) => t.toSpliced(o, 1));
    }
  };
}
export {
  z as useLoadMessages
};
